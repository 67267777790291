import FeedbackLoader from "@mail/feedback-loader";
import EventsLogger from "@mail/feedback-loader/EventsLogger";
import surveys from "./config";

export default function (options) {
    const clientId = options.flashvars.init.myself.id;
    const triggerType = options.ident +'_'+ (!!options.is_unity ? 'html' : 'flash');

    const eventsLogger = new EventsLogger();
    const feedbackLoader = new FeedbackLoader(surveys, {
        /**
         * Идентификатор опроса, который будет отображаться без каких-либо проверок.
         * Если задан, то прочие опросы не отображаются
         * */
        //TODO убрать forceShowSurveyId только для тестирования на dev
        //forceShowSurveyId: '2',
        apiBaseUrl: 'https://corsapi.imgsmail.ru/api/v1',

        surveyLoaderOptions: {
            clientId: clientId,
            eventsLogger: eventsLogger,
            //TODO для холостого хода добавить dryRun: true
            frameLoaderOptions: {
                project: "minigames",
            }
        },
        /**
         * Игнорирование проверки на не показ нескольких опросов в один день
         */
        //TODO убрать loggerOptions, только для тестирования на dev и альфе
        loggerOptions: {
            consoleLog: true,
            logLevel: "INFO"
        },
    });

    console.log('step 2 feedbackLoader >>>', feedbackLoader)
    eventsLogger.push(triggerType);
}