// import 'core-js';
// import 'whatwg-fetch';
import Vue from 'vue';
import mimic from './mimic';
import feedback from "./feedback";

new Vue();

export function config(options) {
    if (options.publicPath) {
        __webpack_public_path__ = options.publicPath;
    }
}

function run(name) {
    if (name === 'adv') {
        return import(/* webpackChunkName: "adv" */ './adv');
    }
    if (name === 'license') {
        return import(/* webpackChunkName: "license" */ './license');
    }
    if (name === 'game') {
        return import(/* webpackChunkName: "game" */ './game');
    }
    if (name === 'mimic') {
        return import(/* webpackChunkName: "mimic" */ './mimic');
    }
    if (name === 'notification') {
        return import(/* webpackChunkName: "notification" */ './notification');
    }
    if (name === 'centrifugo') {
        return import(/* webpackChunkName: "centrifugo" */ './centrifugo');
    }
    if (name === 'api') {
        return import(/* webpackChunkName: "api" */ './api');
    }
}

export function initAdv(options, containerId, completeHandler) {
    run('adv')
        .then(function (module) {
            new module.default(options, containerId, completeHandler);
        });
}

export function initEULA(options, containerId, userId) {
    run('license')
        .then(function (module) {
            new module.default(options, containerId, userId);
        });
}

export function initGame(options, containerId, onCreated) {
    run('game')
        .then(function (module) {
            let game = new module.default(options, containerId);
            if (onCreated) {
                onCreated(game);
            }
        });
}

export function feedbackLoader(options) {
    // TODO раскомментировать и убрать лог после тестирования на дев, для альфы и прода
    // if (!options || options.flashvars.init.myself.is_guest) {
    //     return
    // }
    console.log('step 1 feedbackLoader options >>>', options);
    feedback(options);
}


export function initCentrifugoApi(options, onCreated) {
    run('centrifugo')
        .then(function (module) {
            let centrifugo = new module.default(options);
            if (onCreated) {
                onCreated(centrifugo);
            }
        });
}

export function initNotifications(options, onCreated) {
    run('notification')
        .then(function (module) {
            let notification = new module.default(options);
            if (onCreated) {
                onCreated(notification);
            }
        });
}

export function initApi(completeHandler) {
    run('api')
        .then(function (module) {
            completeHandler();
        });
}

export function insertMimic(conainerId, type, bannerCount) {
    // run('mimic')
    //     .then(function (module) {
    //         new module.default(conainerId, type);
    //     });
     mimic(conainerId, type, false, bannerCount);
}

export function updateMimic(conainerId, type, bannerCount) {
    mimic(conainerId, type, true, bannerCount);
}