const surveys = [
    {
        surveyId: '147',
        /**
         * Параметр permille определяет долю пользователей следующим образом: 0 соответствует 0% пользователей,
         * а 1000 соответствует 100% пользователей. Чтобы результаты опросов имели смысл, необходимо правильно
         * подобрать это значение.
         * Для этого нужно запустить опрос в «холостом режиме». В этом режиме будут производиться все проверки,
         * кроме проверки времени последнего голосования. Также будут отправляться все соответствующие радары.
         * Однако опросы отображаться не будут. Затем можно будет определить релевантное значение параметра
         * permille, используя графики.
         * Для подключения опроса в «холостом режиме»: в surveyLoaderOptions нужно добавить dryRun: true,
         * */
        permille: 1000,
        delay: 0,
        /**
         * Название фичи, при включении которой опрос будет считаться активным
         * */
        //feature: 'survey_2_feature',
        /**
         * Карантин в днях по ответам. Откладывает показ опроса на указанное количество дней
         * с момента последнего ответа на любой вопрос в опросе. Игнорируется, если не задан.
         * */
        repeatAfterDays: 180,
        /**
         * Карантин в днях по показам. Откладывает показ опроса на указанное количество дней
         * с момента последнего показа опроса. Равен `repeatAfterDays`, если не задан.
         * */
        showAfterDays: 15,
        /**
         * Список событий для показа опроса
         * */
        triggerEvents: [
            {
                type: 'durak_html',
                minDelay: 5000
            }
        ]
    },
    {
        surveyId: '148',
        permille: 1000,
        delay: 0,
        repeatAfterDays: 180,
        showAfterDays: 15,
        triggerEvents: [
            {
                type: 'transdurak_flash',
                minDelay: 5000
            }
        ]
    }
]

export default surveys