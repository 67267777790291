import createMimic from '@mail/mimic/dist/minigames.bundle';
import Locator from '@mail/smokescreen/src/Locator';
import * as logger from '@mail/logger';
import anime from 'animejs';
import xray from '@mail/xray';
import features from '@mail/features';

const configs = {
    portalRight: {
        animation: true,
        statId: "2201162",
        slotId: "5128",
        originalSlotId: "949",
        limit: 7,
        type: "sidebar",
        theme: {
            titleFontSize: 1,
            fontSize: 1,
            favicon: true,
            siteCharset: "utf-8",
        }
    },
    okHeader: {
        statId: "908162",
        slotId: "5128",
        originalSlotId: "5292",
        limit: 1,
        type: "sent",
        theme: {
            adFormat: "direct",
            disableHighlight: true,
            fontSize: 1,
            titleFontSize: 1,
            siteBgColor: "FFFFFF",
            headerBgColor: "FFFFFF",
            bgColor: "333333",
            titleColor: "267daf",
            urlColor: "888888",
            textColor: "a9a9a9",
            hoverColor: "888888",
            sitelinksColor: "888888",
            favicon: true,
            noSitelinks: true
        }
    },
    okRight: {
        statId: "1459241",
        slotId: "5128",
        originalSlotId: "145924",
        limit: 5,
        type: "sidebar",
        animation: true,
        theme: {
            adFormat: "direct",
            disableHighlight: true,
            fontSize: 1,
            titleFontSize: 1,
            siteBgColor: "FFFFFF",
            headerBgColor: "FFFFFF",
            bgColor: "FFFFFF",
            titleColor: "207cc3",
            urlColor: "a9a9a9",
            textColor: "a9a9a9",
            hoverColor: "a9a9a9",
            favicon: true,
            noSitelinks: true
        }
    },
    okFooter: {
        statId: "908162",
        slotId: "5128",
        limit: 1,
        type: "sent",
        originalSlotId: "5288",
        theme: {
            adFormat: "direct",
            disableHighlight: true,
            fontSize: 1,
            titleFontSize: 1,
            siteBgColor: "FFFFFF",
            headerBgColor: "FFFFFF",
            bgColor: "333333",
            titleColor: "267daf",
            urlColor: "888888",
            textColor: "a9a9a9",
            hoverColor: "888888",
            sitelinksColor: "888888",
            favicon: true,
            noSitelinks: true
        }

    }
};

const themeMapDefaults = {
    textColor: 'leftText',
    titleColor: 'leftLink',
    urlColor: 'leftInfo',
    hoverColor: 'leftHover',
    siteBgColor: 'bg',
    borderColor: 'borderColor',
};

const animations = [
    { translateX: [250, 0] },
    { translateY: [250, 0] },
    { translateX: [-250, 0] },
    { translateY: [-250, 0] },
    { opacity: [0, 1] },
];

let mimics = {};

export default function (containerId, type, update, limits) {
    let mimic;

    if (!containerId) {
        return;
    }

    const container = document.querySelector(containerId);
    const insertOptions = {
        ...configs[type],
        theme: {...themeMapDefaults, ...configs[type].theme}
    };

    if (limits) {
        insertOptions.limit = limits;
    }

    if (mimics[containerId]) {
        mimic = mimics[containerId];
    } else {
        // не показывать если обновляется не инициализированный мимик
        if (update) {
            return;
        }

        const locator = new Locator({
            enable: true,
            match: [
                'mimic',
                'inline',
                'hide',
                'wrapper',
                'contacts',
                'content',
                'picture',
                'title',
                'warning',
                'age',
                'teaser',
                'banner',
                'promo-image',
            ],
        });

        const brokerOptions = {
            getUrl(method, data) {
                let paramData = $.param(data);
                data.__urlp = '/api-proxy/' + method + '?' + paramData;
                return '/game';
            },
            handleResponse: (method, data) => {
                // console.log('--- handleResponse', method, data);
            },
            APIEncryptor: {
                encryptorOptions: {},
                initXHR: (inputEncryptorOptions, payload) => {
                    payload.settings.data = payload.settings.url.replace('/game?', '');
                    payload.settings.url = '/game';
                }
            }
        };

        xray.setConfig({
            defaultParams: {
                p: 'minigames'
            }
        });

        // Для теста - если надо увидеть реальные имена классов
        // locator.transform = str => str;
        const mimicOptions = {
            pageId: '47187',
            locator,
            features,
            xray,
            logger,
            broker: brokerOptions
        };

        mimic = createMimic(mimicOptions);
        mimics[containerId] = mimic;
    }

    mimic.insert(container, insertOptions)
        .then(result => {
            console.log('insert slot then', result);

            if (insertOptions.animation) {
                anime({
                    targets: containerId,
                    easing: 'easeOutElastic',
                    duration: 500,
                    ...animations[Math.round(Math.random() * (animations.length-1))]
                });
            }
        })
        .catch(result => {
            console.log('insert slot catch', result);
        });
}